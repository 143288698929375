import moment from "moment";

export const getLastDayOfMonth = () => {
    const current = new Date();

    const month = current.getMonth()+1;
    const year = current.getFullYear();

    const lastDayOfCurrentMonth = new Date(year, month, 0);

    if (current.getDate() >= lastDayOfCurrentMonth.getDate() - 15) {
        return moment(lastDayOfCurrentMonth).format("DD.MM.YYYY");
    }

    const lastDayOfNextMonth = new Date(year, month-1 , 0);
    return moment(lastDayOfNextMonth).format("DD.MM.YYYY");
};
